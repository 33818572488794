<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Actuator Type</label>
        <select v-model="actuator_id" class="form-control" @change="$emit('update:val', actuator_id)">
            <option v-for="actuatorOption in cache.curbTypeCache.ECONOMIZER_ACTUATOR_TYPE" :key="actuatorOption[0]" :value="actuatorOption[0]">{{ actuatorOption[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Actuator",
    props: ['val'],
    data() {
        return {
            actuator_id: 0,
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {},
    created() {
        this.actuator_id = this.val;
    },
}
</script>