<template>
    <div v-if="isDeveloper" class="json-viewer">
        <table class="json-table">
            <thead>
            <tr>
                <th>Key</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <!-- Render object keys -->
            <template v-if="isObject(json)">
                <tr v-for="(value, key) in json" :key="key">
                    <td class="key">{{ key }}</td>
                    <td class="value">
                        <PrettyJson :json="value" :depth="depth + 1" v-if="isObject(value) || Array.isArray(value)" />
                        <span v-else>{{ formattedValue(value) }}</span>
                    </td>
                </tr>
            </template>

            <!-- Render array items -->
            <template v-else-if="Array.isArray(json)">
                <tr v-for="(item, index) in json" :key="index">
                    <td class="key">[{{ index }}]</td>
                    <td class="value">
                        <PrettyJson :json="item" :depth="depth + 1" v-if="isObject(item) || Array.isArray(item)" />
                        <span v-else>{{ formattedValue(item) }}</span>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import AppStore from "@/store/App.store";
import { cdiVars } from "@/cdiVars";

export default {
    name: 'PrettyJson',
    props: {
        json: {
            type: [Object, Array],
            required: true
        },
        depth: {
            type: Number,
            default: 0
        }
    },
    computed: {
        isDeveloper() {
            return AppStore.hasRole(cdiVars.CDI_ROLE_SUPER);
        }
    },
    methods: {
        isObject(value) {
            return value !== null && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date) && !(value instanceof RegExp);
        },

        formattedValue(value) {
            if (value === null) {
                return 'null';
            } else if (typeof value === 'boolean') {
                return value ? 'true' : 'false';
            }
            return value;
        },

        hasCircularReference(obj) {
            const seenObjects = new Set();

            function detectCycle(value) {
                if (value && typeof value === 'object') {
                    if (seenObjects.has(value)) {
                        return true;
                    }
                    seenObjects.add(value);
                    for (const key in value) {
                        if (value.hasOwnProperty(key) && detectCycle(value[key])) {
                            return true;
                        }
                    }
                }
                return false;
            }

            return detectCycle(obj);
        },

        isSafeDepth() {
            return this.depth < 5;
        }
    }
};
</script>

<style scoped>
.json-viewer {
    max-height: 800px;
    overflow-y: auto;
    font-family: monospace;
    background-color: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.json-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.json-table th,
.json-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: top;
}

.json-table th {
    background-color: #f0f0f0;
    font-weight: bold;
}

.key {
    font-weight: bold;
    color: #333;
    white-space: nowrap;
}

.value {
    word-break: break-word;
    color: #555;
}

.json-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.json-table tr:nth-child(odd) {
    background-color: #ffffff;
}
</style>
