<template>
    <div>
        <h1>MERs</h1>
        <form @submit.prevent="runReport">
            <Options/>
            <GroupBy/>
            <Fields/>
            <Results/>
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./tracker_mer_rpt_opt.vue"
    import Results from "./utils/Results";
    import Fields from "./utils/Fields";
    import GroupBy from "./utils/GroupBy";

    export default {
        name: "tracker_mer_rpt",
        components: {
            Options,
            Results,
            Fields,
            GroupBy
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {
                        val: new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString(),
                        qsField: '[dates][date_issued_from]'
                    },
                    endDate: {
                        val: new Date().toLocaleDateString(),
                        qsField: '[dates][date_issued_to]'
                    },
                    cdiLocationId: {
                        val: 0,
                        qsField: '[numbers][tm.cdi_location_id]'
                    },
                    faultGroupID: {
                        val: 0,
                        qsField: '[numbers][tm.fault_group_id]'
                    },
                    companyID: {
                        val: 0,
                        qsField: '[numbers][u.company_id]'
                    },
                    shippingCoID: {
                        val: 0,
                        qsField: '[numbers][t.shipping_co_id]'
                    },
                    distributorID: {
                        val: 0,
                        qsField: '[numbers][t.distributor_id]'
                    },
                    creditPending: {
                        val: 0,
                        qsField: '[isset][tm.credit_pending]'
                    },
                    trackerID: {
                        val: '',
                        qsField: '[numbers][tm.tracker_id]',
                        defaultVal: ''
                    },
                    causeID: {
                        val: 0,
                        qsField: '[numbers][tm.cause_id]',
                        defaultVal: 0
                    },
                    resolutionID: {
                        val: 0,
                        qsField: '[numbers][tm.resolution_id]',
                        defaultVal: 0
                    },
                    secondarySalesID: {
                        val: 0,
                        qsField: '[numbers][tm.secondary_sales_id]',
                        defaultVal: 0
                    },
                    secondaryDesignID: {
                        val: 0,
                        qsField: '[numbers][tm.secondary_design_id]',
                        defaultVal: 0
                    },
                    secondaryOperationsID: {
                        val: 0,
                        qsField: '[numbers][tm.secondary_operations_id]',
                        defaultVal: 0
                    },
                    taskID: {
                        val: 0,
                        qsField: '[numbers][tt.task_id]',
                        defaultVal: 0
                    },
                    cdi_issue_closed: {
                        val: 0,
                        qsField: '[numbers][tm.cdi_issue_closed]',
                        defaultVal: 0
                    },
                    assigned_sales: {
                        val: 0,
                        qsField: '[numbers][us.user_id]'
                    },
                    inside_sales: {
                        val: 0,
                        qsField: '[numbers][u.inside_sales_user_id]'
                    }
                },
                groupByFields: {
                    date_issued: {
                        label: 'Date Issued',
                        checked: true
                    },
                    distributor: {
                        label: 'Customer',
                        checked: false
                    },
                    company_name: {
                        label: 'Company',
                        checked: false
                    },
                    shipping_co: {
                        label: 'Shipper',
                        checked: false
                    },
                    fault_group: {
                        label: 'Type',
                        checked: false
                    },
                    secondary_sales: {
                        label: 'Sales Secondary',
                        checked: false
                    },
                    secondary_operations: {
                        label: 'Operations Secondary',
                        checked: false
                    },
                    secondary_design: {
                        label: 'Design Secondary',
                        checked: false
                    },
                    resolution: {
                        label: 'Resolution',
                        checked: false
                    },
                    credit_pending: {
                        label: 'Pending Credit',
                        checked: false
                    },
                    cdi_location: {
                        label: 'CDI Location',
                        checked: false
                    }
                },
                dataFields: {
                    tracker_mer_id: {
                        label: 'MER ID',
                        checked: true
                    },
                    tracker_id: {
                        label: 'Order ID',
                        checked: true
                    },
                    credit_id: {
                        label: 'Credit ID',
                        checked: false
                    },
                    date_issued: {
                        label: 'Date Issued',
                        checked: true
                    },
                    distributor: {
                        label: 'Customer',
                        checked: false
                    },
                    company: {
                        label: 'Company',
                        checked: false
                    },
                    shipping_co: {
                        label: 'Shipper',
                        checked: false
                    },
                    fault_group: {
                        label: 'Type',
                        checked: true
                    },
                    issue_description: {
                        label: 'Issue Description',
                        checked: false
                    },
                    secondary_sales: {
                        label: 'Sales Secondary',
                        checked: false
                    },
                    secondary_operations: {
                        label: 'Operations Secondary',
                        checked: false
                    },
                    secondary_design: {
                        label: 'Design Secondary',
                        checked: false
                    },
                    resolution: {
                        label: 'Resolution',
                        checked: false
                    },
                    final_cost_to_cdi: {
                        label: 'Credit Amount',
                        checked: true
                    },
                    cdi_estimated_cost: {
                        label: 'Total CDI Cost',
                        checked: false
                    },
                    credit_pending: {
                        label: 'Pending Credit',
                        checked: false
                    },
                    last_update: {
                        label: 'Last Update',
                        checked: false
                    },
                    employees_on_task: {
                        label: 'Employee(s)',
                        checked: false
                    },
                    cdi_location: {
                        label: 'CDI Location',
                        checked: false
                    },
                    actual_ship: {
                        label: 'Ship Date',
                        checked: false
                    },
                    cdi_issue_closed:{
                        label: 'Status',
                        checked: false
                    },
                    assigned_sales:{
                        label: 'Regional Sales Rep',
                        checked: false
                    },
                    inside_sales: {
                        label: 'Sales Support Rep',
                        checked:false },
                    person_responsible: {
                        label: 'Person Responsible',
                        checked: true
                    }
                }
            }
        },
        methods: {
            runReport: function () {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['MERs']);
            this.$set(store.state.results, this.$options.name, "");
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>