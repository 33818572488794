<template>
  <div>
    <input type=button class="cdi_button btn btn-default" value="New Branch Item Discount" @click="showForm = true">
    <form>
      <div class="panel panel-default spacing_top" v-show="showForm">
        <div class="panel-heading"></div>
        <div class="row panel-body">
          <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Item</label>
            <Typeahead cdi_type="curb" v-bind:ID.sync="formFields.curbID.val" />
          </div>
          <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Discount %</label>
            <input type="text" class="form-control" placeholder="8" v-model="formFields.discount.val">
          </div>
          <div class="col-xs-12">
            <input class="cdi_button btn btn-default" type="button" value="Save Item Discount" @click="saveDiscount()">
            <input type="button" value="Cancel" class="cdi_button btn btn-default" @click="clearForm()">
          </div>
        </div>
      </div>
    </form>
    <div class="results">
      <table class="table">
        <thead>
        <tr>
          <th>Item</th>
          <th>Discount %</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="itemDiscount in data.item_discount" v-bind:key="itemDiscount.branch_curb_discount_id">
          <td>{{ itemDiscount.nomenclature }}   <span class="glyphicon glyphicon-trash cdi_link" @click="deleteDiscountConfirm(itemDiscount.branch_curb_discount_id)"></span></td>
          <td>{{ itemDiscount.discount }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <ConfirmBox
        v-if="confirmBox"
        :heading="'Delete Item Discount?'"
        :body="'Would you like to delete this Item Discount?'"
        @close="deleteDiscount"
    />

  </div>

</template>

<script>
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import ConfirmBox from '@/components/utils/ConfirmBox';
import { store } from '@/store/BusinessObject.store.js';
import helpers from "@/store/helpers";
export default {
  name: "ItemDiscount",
  components: {
    Typeahead,
    ConfirmBox,
  },
  data() {
    return {
      showForm: false,
      formFields: {
        discount: { val: '', qsField: '[discount]' },
        curbID: { val: '', qsField: '[curb_id]' },
      },
      confirmBox: false,
      deleteID: 0,
    }
  },
  props: ['data'],
  methods: {
    clearForm: function() {
      this.showForm = false;
      this.formFields.discount.val = '';
      this.formFields.curbID.val = '';
    },
    saveDiscount: function() {
      store.api('save_item_discount', helpers.getQSPairs(this.formFields), 'object.data')
          .then(() => this.clearForm());
    },
    deleteDiscountConfirm: function(curbDiscountID) {
      this.deleteID = curbDiscountID;
      this.confirmBox = true;
    },
    deleteDiscount: function(confirm) {
      if (confirm){
        store.api('delete_item_discount', {'params[branch_curb_discount_id]': this.deleteID}, 'object.data');
      }
      this.confirmBox = false;
      this.deleteID = 0;
    },
  },
}
</script>

<style scoped>

</style>