export default {
    CDI_LOCATIONS: [
        [3, 'Charlotte'],
        [4, 'Dallas'],
        [1, 'Minneapolis'],
        [5, 'Phoenix'],
        [2, 'Seattle'],
        [6, 'HQ'],
        [7, 'New Location']
    ],
    CDI_LOCATIONS_KV: {
        3: 'Charlotte',
        4: 'Dallas',
        1: 'Minneapolis',
        5: 'Phoenix',
        2: 'Seattle',
        6: 'HQ',
        7: 'New Location'
    }
}