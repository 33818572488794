<template>
    <div>
        <h1>Customer Summary</h1>
        <form @submit.prevent="runReport">
            <Options/>
            <GroupBy/>
            <Fields/>
            <Results/>
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './customer_summary_opt.vue'
    import GroupBy from './utils/GroupBy.vue'
    import Fields from './utils/Fields.vue'
    import Results from "./utils/Results";

    export default {
        name: "customer_summary",
        components: {
            Options,
            GroupBy,
            Fields,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    year: {
                        val: new Date().getFullYear(),
                        qsField: '[year]'
                    },
                    companyID: {
                        val: '',
                        qsField: '[numbers][c.company_id]'
                    },
                    distributor: {
                        val: '',
                        qsField: '[numbers][u.user_id]'
                    },
                    cdiLocationID: {
                        val: '',
                        qsField: '[numbers][u.assigned_cdi_location_id]'
                    },
                    salesUserID: {
                        val: '',
                        qsField: '[numbers][u.sales_user_id]'
                    },
                    insideSalesUserID: {
                        val: '',
                        qsField: '[numbers][u.inside_sales_user_id]'
                    },
                    nationalSalesTeams: {
                      val: 0,
                      qsField: '[numbers][u.national_sales_team_id]'
                    },
                    targetedCustomer: {
                        val: 0,
                        qsField: '[isset][us.targeted]'
                    },
                    enabledOnly: {
                        val: 0,
                        qsField: '[isnot][u.disable]'
                    }
                },
                groupByFields: {
                    company_name: {label: 'Company', checked: true},
                    territory_name: {label: 'Territory'},
                    city: {label: 'City'},
                    state: {label: 'State'},
                    cdi_location: {label: 'CDI Location'},
                },
                dataFields: {
                    user_id: {
                        label: 'User ID',
                        checked: true
                    },
                    company_name: {
                        label: 'Company',
                        checked: true
                    },
                    distributor: {
                        label: 'Customer',
                        checked: true
                    },
                    territory_name: {
                        label: 'Territory',
                        checked: false,
                    },
                    city: {
                        label: 'City',
                        checked: false
                    },
                    state: {
                        label: 'State',
                        checked: false
                    },
                    phone: {
                        label: 'Phone',
                        checked: false
                    },
                    cdi_location: {
                        label: 'CDI Location',
                        checked: false
                    },
                    prev_year_sales: {
                        label: this.previous_year() + ' Total Sales',
                        checked: true,
                        disabled: true
                    },
                    mtd_incoming: {
                        label: this.previous_year() + ' Sales YTD',
                        checked: true,
                        disabled: true
                    },
                    ytd_quote: {
                        label: this.year() + ' Quote YTD',
                        checked: true,
                        disabled: true
                    },
                    ytd_outgoing: {
                        label: this.year() + ' Sales YTD',
                        checked: true,
                        disabled: true
                    },
                    sales_ytd_percentage: {
                        label: this.year_small() + ' YTD Sales',
                        checked: true,
                        disabled: true
                    },
                    quote_conversion: {
                        label: 'Quote Conversion',
                        checked: true,
                        disabled: true
                    },
                    national_sales_team: {
                      label: 'National Sales Team',
                      checked: false,
                      disabled: false
                    },
                }
            }
        },
        methods: {
            runReport: function () {
                store.runReport();
            },
            previous_year() {
                const year = new Date().getFullYear() -1;
                return year;
            },
            year() {
                const year = new Date().getFullYear();
                return year;
            },
            year_small() {
                const year_pre = new Date().getFullYear() -1;
                const year = new Date().getFullYear();
                return year_pre.toString().substr(-2) +'/' + year.toString().substr(-2);
            }
        },
        created() {
            this.$appStore.setTitle(['Customer Summary']);
            this.$set(store.state.results, this.$options.name, '');
            store.state.runOnLoad = false;
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>