<template>
    <div>
        <h2>{{curb.tracker_invoice_id ? `${curb.line}: ${curb.nomenclature}` : 'Add Item'}}</h2>
        <Adapter v-if="curb.curb_type_id === cache.curbTypeCache.ADAPTER" :curb="curb" @cancel="cancel" @save="save" />
        <Straight v-if="curb.curb_type_id === cache.curbTypeCache.STRAIGHT_CURB" :curb="curb" @cancel="cancel" @save="save" />
        <Pitched v-if="curb.curb_type_id === cache.curbTypeCache.PITCHED" :curb="curb" @cancel="cancel" @save="save" />
        <!-- Metal Build Curb uses same form as pitched -->
        <Pitched v-if="curb.curb_type_id === cache.curbTypeCache.METAL_BUILDING_CURB" :curb="curb" @cancel="cancel" @save="save" />
        <ExhaustFan v-if="curb.curb_type_id === cache.curbTypeCache.EXHAUST_FAN_CURB" :curb="curb" @cancel="cancel" @save="save" />
        <BurglarBar v-if="curb.curb_type_id === cache.curbTypeCache.BURGLAR_BAR" :curb="curb" @cancel="cancel" @save="save" />
        <AdjustablePitched v-if="curb.curb_type_id === cache.curbTypeCache.ADJUSTABLE_PITCHED_CURB" :curb="curb" @cancel="cancel" @save="save" />
        <Custom v-if="curb.curb_type_id === cache.curbTypeCache.CUSTOM" :curb="curb" @cancel="cancel" @save="save" />
        <IsolationRail v-if="curb.curb_type_id === cache.curbTypeCache.ISO_RAIL" :curb="curb" @cancel="cancel" @save="save" />
        <Miscellaneous v-if="curb.curb_type_id === cache.curbTypeCache.MISCELLANEOUS" :curb="curb" @cancel="cancel" @save="save" />
        <Economizer v-if="curb.curb_type_id === cache.curbTypeCache.ECONOMIZER" :curb="curb" @cancel="cancel" @save="save" />
        <ERVCombo v-if="curb.curb_type_id === cache.curbTypeCache.ERV_COMBO" :curb="curb" @cancel="cancel" @save="save" />
        <EquipmentRails v-if="curb.curb_type_id === cache.curbTypeCache.EQUIPMENT_RAILS" :curb="curb" @cancel="cancel" @save="save" />
        <Clips v-if="curb.curb_type_id === cache.curbTypeCache.CLIPS" :curb="curb" @cancel="cancel" @save="save" />
        <MultiZoneAdapter v-if="curb.curb_type_id === cache.curbTypeCache.MULTI_ZONE_ADAPTERS" :curb="curb" @cancel="cancel" @save="save" />
        <IsolationStraight v-if="curb.curb_type_id === cache.curbTypeCache.ISO_CURB" :curb="curb" @cancel="cancel" @save="save" />
        <IsolationAdapter v-if="curb.curb_type_id === cache.curbTypeCache.ISO_ADAPTER" :curb="curb" @cancel="cancel" @save="save" />
        <CurbExtension v-if="curb.curb_type_id === cache.curbTypeCache.EXTENSION" :curb="curb" @cancel="cancel" @save="save" />
        <Stradapter v-if="curb.curb_type_id === cache.curbTypeCache.STRADAPTER" :curb="curb" @cancel="cancel" @save="save" />
        <Vertical v-if="curb.curb_type_id === cache.curbTypeCache.VERT_CURB" :curb="curb" @cancel="cancel" @save="save" />
        <VRFStraight v-if="curb.curb_type_id === cache.curbTypeCache.VRF_STRAIGHT_CURB" :curb="curb" @cancel="cancel" @save="save" />
        <VRFPitched v-if="curb.curb_type_id === cache.curbTypeCache.VRF_PITCHED_CURB" :curb="curb" @cancel="cancel" @save="save" />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import curbTypeCache from '@/cache/curbType.cache';
import Adapter from './add_item/Adapter';
import Pitched from './add_item/Pitched';
import Straight from './add_item/Straight';
import ExhaustFan from "./add_item/ExhaustFan";
import BurglarBar from "./add_item/BurglarBar";
import AdjustablePitched from "./add_item/AdjustablePitched";
import Custom from "./add_item/Custom";
import IsolationRail from "./add_item/IsolationRail";
import Miscellaneous from "./add_item/Miscellaneous";
import ERVCombo from "./add_item/ERVCombo";
import EquipmentRails from "./add_item/EquipmentRails";
import Clips from "./add_item/Clips";
import MultiZoneAdapter from "./add_item/MultiZoneAdapter";
import IsolationStraight from "./add_item/IsolationStraight";
import IsolationAdapter from "./add_item/IsolationAdapter";
import CurbExtension from "./add_item/CurbExtension";
import Stradapter from "./add_item/Stradapter";
import Vertical from "./add_item/Vertical";
import VRFStraight from "@/components/businessObjects/tracker/add_item/VRFStraight";
import VRFPitched from "@/components/businessObjects/tracker/add_item/VRFPitched";
import Economizer from "@/components/businessObjects/tracker/add_item/Economizer";



export default {
    name: "AddItem",
    components: {
        VRFPitched,
        VRFStraight,
        Adapter,
        Straight,
        Pitched,
        ExhaustFan,
        BurglarBar,
        AdjustablePitched,
        Custom,
        IsolationRail,
        Miscellaneous,
        ERVCombo,
        EquipmentRails,
        Clips,
        MultiZoneAdapter,
        IsolationStraight,
        IsolationAdapter,
        CurbExtension,
        Stradapter,
        Vertical,
        Economizer
    },
    props: ["curb"],
    data() {
        return {
            state: store.state,
            cache: {
                curbTypeCache
            },
        }
    },
    methods: {
        save: function(curb) {
            let params = {};
            const trackerInvoiceID = curb.tracker_invoice_id || 0;

            for (const field in curb) {
                params[`params[${field}]`] = curb[field];
            }
            params['params[tracker_id]'] = this.state.object.id;
            params['params[curb_type_id]'] = this.curb.curb_type_id;

            if (curb.conforming == 0){
              params['params[is_oshpd]'] = 0;
            }

            store.apiGeneric(`tracker_curb/save_tracker_curb_data/${trackerInvoiceID}`, params)
                .then(() => this.state.currentView = 'item');
        },
        cancel: function() {
            this.state.currentView = 'item';
        },
    },
}
</script>

<style scoped>

</style>