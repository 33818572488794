import curbTypeCache from '@/cache/curbType.cache';

export const curbStore = {
	state: {
		name: 'curb',
		title: 'Item',
		titlePlural: 'Items',
		nav: {
			base: 'curb',
			newView: true,
			views: [
				{view: 'details', label: 'Details'},
				{view: 'variants', label: 'Variants'},
				{view: 'conformingStamp', label: 'Conforming Stamp Files'},
				{view: 'lennox', label: 'Lennox'},
				{view: 'stock', label: 'Stock'},
				{view: 'history', label: 'History'}
			]
		},
		search: {
			endPoint: 'curbs',
			formFields: {
				nomenclature: {
					val: '',
					qsField: '[strings][nomenclature]',
					label: 'Nomenclature',
					component: 'SearchTextInput'
				},
				curbTypeID: {
					val: 0,
					qsField: '[numbers][c.curb_type_id]',
					label: 'Item Type',
					component: 'SearchSelectInput',
					options: curbTypeCache.CURB_TYPES
				},
				enabledOnly: {
					val: 1,
					qsField: '[enabled_only]',
					label: 'Enabled Only',
					component: 'SearchCheckboxInput'
				},
				isTurretReady: {
					val: '',
					qsField: '[isset][turret_ready]',
					label: 'Turret',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				isNotTurretReady: {
					val: '',
					qsField: '[isnot][turret_ready]',
					label: 'Not Turret Ready',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				hasDefaultEstimator: {
					val: '',
					qsField: '[isset][default_estimate_id]',
					label: 'Estimator',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				hasNoDefaultEstimator: {
					val: '',
					qsField: '[isnot][default_estimate_id]',
					label: 'No Estimator',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				hasPoints: {
					val: '',
					qsField: '[isset][curb_points]',
					label: 'Points',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				hasNoPoints: {
					val: '',
					qsField: '[isnot][curb_points]',
					label: 'No Points',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				hasThreeD: {
					val: '',
					qsField: '[has3d]',
					label: '3D',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				hasNoThreeD: {
					val: '',
					qsField: '[no3d]',
					label: 'No 3D',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				direction: {
					val: 'DESC',
					qsField: '[direction]'
				},
                searchIndex: {val: 0, qsField: '[search_index]'},
			},
			resultFields: {
				curb_id: {
					header: 'ID',
					link: 'curb'
				},
				nomenclature: {
					header: 'Nomenclature',
					link: ''
				},
				curb_type: {
					header: 'Type',
					link: ''
				},
				curb_points: {
					header: 'Points',
					link: ''
				},
				turret_ready: {
					header: 'Turret Ready',
					link: ''
				},
				default_estimate_id: {
					header: 'Estimator',
					link: ''
				},
				catalog_num: {
					header: 'Cat#',
					link: ''
				},
				curb_files_types: {
					header: 'Files',
					link: ''
				},
				eco_id: {
					header: 'ECO',
					link: 'eco'
				},
				price: {
					header: 'Price',
					link: ''
				},
			},
		},
		object: {
			fields: [
				{
					name: 'curb_id',
					label: 'Item ID',
					component: 'DetailsStatic'
				},
				{
					name: 'curb_points',
					label: 'Points',
					component: 'DetailsText'
				},
				{
					name: 'price',
					label: 'Price',
					component: 'DetailsText'
				},
				{
					name: 'weight',
					label: 'Ship Weight',
					component: 'DetailsText'
				},
				{
					name: 'weight_metal',
					label: 'Metal Weight',
					component: 'DetailsText'
				},
				{
					name: 'turret_ready',
					label: 'Turret Ready',
					component: 'DetailsCheckbox'
				},
				{
					name: 'direct_replacement',
					label: 'Direct Replacement',
					component: 'DetailsCheckbox'
				},
				{
					name: 'not_kit',
					label: 'Not Kit',
					component: 'DetailsCheckbox'
				},
				{
					name: 'curb_lead_time',
					label: 'Lead Time',
					component: 'DetailsText'
				},
				{
					name: 'default_estimate_id',
					label: 'Estimator',
					component: 'DetailsView',
					view: 'estimator',
					emitIfPositive: true,
					clickableValue: true
				},
				{
					name: 'insulation_kit',
					label: 'Insulation Kit',
					component: 'DetailsCheckbox'
				},
				{
					name: 'gauge',
					label: 'Gauge',
					component: 'DetailsSelect',
					options: curbTypeCache.CURB_GAUGES
				},
				{
					name: 'is_hem',
					label: 'HEM',
					component: 'DetailsCheckbox'
				},
				{
					name: 'fixed_labor_hr',
					label: 'Est Hours',
					component: 'DetailsText'
				},
				{
					name: 'labor_rate',
					label: 'Labor Rate',
					component: 'DetailsStatic'
				},
				{
					name: 'est_labor',
					label: 'Est Labor',
					component: 'DetailsStatic'
				},
				{
					name: 'alt_brace_kit',
					label: 'Alt Brace Kit',
					component: 'DetailsCheckbox'
				},
				{
					name: 'disable',
					label: 'Disable',
					component: 'DetailsCheckbox'
				}
			]
		}
	}
}