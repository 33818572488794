<template>
    <div v-if="appStore.hasRole(cdiVars.CDI_ROLE_SUPER)" class="my-5">
        <button @click="toggleState" >{{ (showState) ? 'Hide Object State' : 'Show Object State' }}</button>
        <PrettyJson :json="object" v-if="showState" />
    </div>
</template>

<script>
import PrettyJson from "@/components/utils/PrettyJson.vue";
import appStore from "@/store/App.store";
import {cdiVars} from "@/cdiVars";

export default {
    name: "ObjectState",
    computed: {
        cdiVars() {
            return cdiVars
        },
        appStore() {
            return appStore
        }
    },
    components: {PrettyJson},
    data() {
        return {
            showState: false,
        }
    },
    props: ["object"],
    methods: {
        toggleState() {
            this.showState = !this.showState;
        }
    }
}
</script>