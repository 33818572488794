<template>
    <div>
        <div v-if="id">
            <h1>{{ state.object.data.cdi_part_num }}</h1>

            <FastDraw :existingProjects="state.object.data.auto_drawing_projects_eligible" v-if="isCurrentView('fastDraw') && id" />
            <Brands :existingBrands="state.object.data.brands" v-else-if="isCurrentView('brands') && id" />

            <div v-else>
                <Details :data="state.object.data" v-show="isCurrentView('details')" />
                <ConformingSelect :data="state.object.data" v-show="isCurrentView('details')" />
                <EconomizerSelect :data="state.object.data" v-show="isCurrentView('details')" />
                <Notebook :notes="state.object.data.notes" :hideRead="true" v-show="isCurrentView('details')" />
                <History :history="state.object.data.history" v-show="isCurrentView('details')" />
            </div>
        </div>

        <New v-else-if="isCurrentView('new')" />
        
        <Search v-else :showMoreButton="false" />
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store.js";
    import { partStore } from "./Part.store.js";
    import Search from "@/components/businessObjects/utils/Search";
    import Details from "@/components/businessObjects/utils/Details";
    import New from "@/components/businessObjects/part/New";
    import History from "@/components/businessObjects/utils/History";
    import Notebook from "@/components/businessObjects/utils/Notebook";
    import FastDraw from "@/components/businessObjects/part/FastDraw";
    import Brands from "@/components/businessObjects/part/Brands";
    import ConformingSelect from "@/components/businessObjects/part/ConformingSelect";
    import EconomizerSelect from "@/components/businessObjects/part/EconomizerSelect.vue";

    export default {
        name: "Container.vue",
        data() {
            return {
                state: store.state,
                partState: partStore.state,
            }
        },
        props: ["id"],
        components: {
            EconomizerSelect,
          ConformingSelect,
            Brands,
            FastDraw,
            Notebook,
            History,
            New,
            Search,
            Details
        },
        created() {
            store.initialize(this.partState);
            this.load();
        },
        methods: {
            load: function() {
                if (this.id) {
                    store.load(this.id)
                        .then(() => this.$appStore.setTitle([this.state.object.data.cdi_part_num, this.state.titlePlural]));
                }
            },
            isCurrentView: function(view) {
                return view === this.state.currentView;
            }
        },
        computed: {
            currentView: function() {
                return this.state.currentView;
            }
        },
        watch: {
            id: function () {
                this.load();
            },
            currentView: function() {
                if(this.currentView == 'search'){
                    window.location.reload()
                }
            }
        }
    }
</script>

<style scoped>

</style>