<template>
    <div>
        <div class="row">
            <div class="form-group col-xs-12">
                <textarea class="form-control" v-model="newNote"></textarea>
            </div>
            <div class="form-group col-xs-12">
                <button class="btn btn-default" @click="add">Add Note</button>
            </div>
        </div>

        <h3>Search Notes</h3>
        <div class="panel panel-default">
            <div class="row panel-body">
                <div class="form-group col-md-3 col-sm-4 col-xs-6">
                    <label>Start Date</label>
                    <DatePicker v-model="startDate" :input-attributes="{ class: 'form-control' }"/>
                </div>
                <div class="form-group col-md-3 col-sm-4 col-xs-6">
                    <label>End Date</label>
                    <DatePicker v-model="endDate" :input-attributes="{ class: 'form-control' }"/>
                </div>
                <div class="col-sm-12">
                    <label>Contributors</label>
                    <div class="panel-body report_options">
                        <div v-for="user in tracker.note_users" v-bind:key="user.id">
                            <input type="checkbox" v-model="noteUsers" :id="user.id" :value="user.id"
                                   :checked="user.checked"/>
                            <label :for="user.id">{{ user.name }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <button @click="search" class="btn btn-default btn_min_width">
                        Search
                    </button>
                </div>
            </div>
        </div>

        <div v-if="tracker.actual_ship">
            <h3>MER DETAILS</h3>
            <div class="row details_container">
                <div class="col-xs-12" v-for="(mer, item) in tracker.tracker_mer" v-bind:key="item">
                    <router-link :to="`/tracker_mer/${ mer.tracker_mer_id }`">
                        MER #: {{ mer.tracker_mer_id }}
                    </router-link>
                </div>
                <div>
                    <div class="form-group col-sm-6 col-xs-12">
                        <label>*Type</label>
                        <select v-model="faultGroup" class="form-control">
                            <option value="0">
                                Start an MER:
                            </option>
                            <option v-for="[id, type] in cache.trackerCache.TRACKER_MER_REMAKE_TYPES" v-bind:key="id"
                                    :value="id">
                                {{ type }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-sm-6 col-xs-12" v-if="faultGroup">
                        <label>*Issue Description</label>
                        <select v-model="issue" class="form-control" @change="createMER">
                            <option v-for="[id, type] in this.cache.trackerCache.TRACKER_MER_CAUSE_TYPES"
                                    v-bind:key="id" :value="id">
                                {{ type }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="hasNotes">
            <h3>Options</h3>
            <label>
                <input type='checkbox' v-model="tracker.check_notes" @change="updateCheckNotes" :false-value="0"
                       :true-value="1">
                Show Notice on Scheduler
            </label>
        </div>

        <div v-for="[key, rows] in noteTypes" v-bind:key="key">
            <div v-show="rows.length > 0">
                <Notebook :notes="rows"
                          :hideAdd="true"
                          :header="capitalizeFirstLetter(key)"
                          :onlyEmit="true"
                          @read="updateNotes"
                          @strike="updateNotes"
                />
            </div>
        </div>

        <div v-if="typeof tracker !== 'undefined'">
            <Files :files="tracker.customer_files" :visibilityType="'external'" title="Files" @update="updateNotes"
                   :emitOnly="true"/>
            <Files :files="tracker.internal_files" :visibilityType="'internal'" title="Internal Files"
                   @update="updateNotes" :emitOnly="true"/>
        </div>
    </div>
</template>

<script>
import {store} from "@/store/BusinessObject.store";
import trackerCache from "@/cache/tracker.cache";
import DatePicker from "@/components/utils/DatePicker";
import helpers from "@/store/helpers";
import Notebook from "@/components/businessObjects/utils/Notebook";
import Files from "@/components/businessObjects/utils/Files";
import appStore from "@/store/App.store";

export default {
    name: "TrackerNotebook",
    components: {
        Files,
        Notebook,
        DatePicker
    },
    data() {
        return {
            state: store.state,
            appState: appStore.state,
            tracker: {
                notes: {
                    build: [],
                    field: [],
                    invoice: [],
                    quote: [],
                },
            },
            cache: {
                trackerCache
            },
            newNote: null,
            noteUsers: [],
            startDate: null,
            endDate: null,
            faultGroup: 0,
            issue: 0,
            scheduledShip: '',
            distributorPo: '',
            sendConfirmationEmail: 1,
        }
    },
    methods: {
        load: function () {
            store.api('to_json_notebook').then(data => this.tracker = data);
        },
        add: function () {
            let params = {
                'params[cdi_note]': this.newNote
            };

            this.newNote = null;
            store.api('save_note', params).then(data => this.tracker = data);
        },
        search: function () {
            let options = {
                'params[datetimes][cdi_datetime_from]': this.startDate,
                'params[datetimes][cdi_datetime_to]': this.endDate,
                'params[user_ids]': this.noteUsers,
            };

            store.api('to_json_notebook', options).then(data => this.tracker = data);
        },
        updateNotes: function (data) {
            this.tracker = data;
        },
        createMER: function () {
            if (this.faultGroup == 0 || this.issue == 0) {
                return;
            }
            store.api('save_tracker_mer', {
                'params[fault_group_id]': this.faultGroup,
                'params[cause_id]': this.issue,
                'params[cdi_location_id]': this.state.object.data.cdi_location_id
            }).then(data => this.tracker = data).then(this.faultGroup = 0, this.issue = 0)
        },
        updateCheckNotes: function () {
            store.save('check_notes', this.tracker.check_notes);
        },
        capitalizeFirstLetter: function (string) {
            return helpers.capitalizeFirstLetter(string);
        }
    },
    computed: {
        currentView: function () {
            return this.state.currentView;
        },
        hasNotes: function () {
            if (this.tracker.notes.field.length) {
                return true;
            } else if (this.tracker.notes.quote.length) {
                return true;
            } else if (this.tracker.notes.invoice.length) {
                return true;
            } else if (this.tracker.notes.build.length) {
                return true;
            }

            return false;
        },
        noteTypes: function () {
            if (typeof this.tracker.notes === 'undefined') {
                return {};
            }

            return Object.entries(this.tracker.notes);
        },
        notes: function () {
            return this.tracker.notes;
        }
    },
    watch: {
        currentView: function () {
            if (this.currentView == 'notebook') {
                this.load();
            }
        }
    },
}
</script>

<style scoped>
</style>