<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Product Type</label>
        <select v-model="product_type_id" class="form-control" @change="$emit('update:val', product_type_id)">
            <option v-for="productOption in cache.curbTypeCache.ECONOMIZER_PRODUCT_TYPE" :key="productOption[0]" :value="productOption[0]">{{ productOption[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Product",
    props: ['val'],
    data() {
        return {
            product_type_id: '',
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {
    },
    created() {
        this.product_type_id = this.val;
    },
}
</script>

<style scoped>
