<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Orientation Type</label>
        <select v-model="orientation_id" class="form-control" @change="$emit('update:val', orientation_id)">
            <option v-for="orientationOption in cache.curbTypeCache.ECONOMIZER_ORIENTATION_TYPE" :key="orientationOption[0]" :value="orientationOption[0]">{{ orientationOption[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Orientation",
    props: ['val'],
    data() {
        return {
            orientation_id: '',
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {},
    created() {
        this.orientation_id = this.val;
    },
}
</script>

