import stateCache from '@/cache/state.cache';
import branchCache from '@/cache/branch.cache';

export const branchStore = {
	state: {
		name: 'branch',
		title: 'Branch',
		titlePlural: 'Branches',
		nav: {
			base: 'branch',
			newView: false,
			hideSearch: true,
			hideUpload: true,
			views: [
				{ view: 'dashboard', label: 'Dashboard' },
				{ view: 'details', label: 'Details' },
				{ view: 'volDiscountBranch', label: 'Volume Discounts' },
				{ view: 'itemDiscountBranch', label: 'Item Discounts' },
			]
		},
		search: {
			endPoint: 'users',
			formFields: {},
			resultFields: {}
		},
		object: {
			fields: [
				{
					name: 'branch_name',
					label: 'Branch',
					component: 'DetailsText'
				},
				{
					name: 'phone',
					label: 'Phone',
					component: 'DetailsText'
				},
				{
					name: 'address',
					label: 'Address',
					component: 'DetailsText'
				},
				{
					name: 'city',
					label: 'City',
					component: 'DetailsTypeahead',
					cdiType: 'city',
					displayField: 'city'
				},
				{
					name: 'state',
					label: 'State',
					component: 'DetailsSelect',
					options: stateCache.STATES
				},
				{
					name: 'zip',
					label: 'Zip',
					component: 'DetailsText'
				},
				{
					name: 'branch_type',
					label: 'Branch Type',
					component: 'DetailsSelect',
					options: [[0,'None']].concat(branchCache.TYPES)
				},
				{
					name: 'disable',
					label: 'Disable',
					component: 'DetailsCheckbox'
				}
			]
		}
	}
}