<template>
    <div>
        <div class="row">
            <RTUTag :val.sync="editCurb.tag" />
            <Model
                :label="'New Model'"
                :val="editCurb.new_model"
                @update="(newModel) => {
                    editCurb.cdi_part_id_new = newModel.cdi_part_id;
                    editCurb.new_model_id = newModel.unit_model_id;
                    editCurb.new_model = newModel.label;
                }"
            />
            <Product :val.sync="editCurb.accessory_metadata.product_type_id" />
        </div>
        <div class="row">
            <Orientation :val.sync="editCurb.accessory_metadata.orientation_id" />
            <Control :val.sync="editCurb.accessory_metadata.control_id" />
            <Actuator :val.sync="editCurb.accessory_metadata.actuator_id" />
            <Sensor :val.sync="editCurb.accessory_metadata.sensor_id" />
        </div>
        <div class="spacing_top">
            <input class="btn btn-default" type="button" value="Save" @click="save">
            <input class="btn btn-default" type="button" value="Cancel" @click="cancel">
        </div>
    </div>
</template>

<script>
import RTUTag from './fields/RTUTag';
import Model from "./fields/Model";
import Product from "@/components/businessObjects/tracker/add_item/fields/EconoProduct.vue";
import Orientation from "@/components/businessObjects/tracker/add_item/fields/EconoOrientation.vue";
import Control from "@/components/businessObjects/tracker/add_item/fields/EconoControl.vue";
import Actuator from "@/components/businessObjects/tracker/add_item/fields/EconoActuator.vue";
import Sensor from "@/components/businessObjects/tracker/add_item/fields/EconoSensor.vue";


export default {
    name: "Economizer",
    components: {
        Orientation,
        RTUTag,
        Model,
        Product,
        Control,
        Actuator,
        Sensor
    },
    props: ["curb"],
    data() {
        return {
            editCurb: {
                side_type_id: 0,
                conforming: 0,
                tracker_invoice_id: 0,
                tag: '',
                cdi_part_id_new: 0,
                accessory_metadata: {
                    product_type_id: 0,
                    orientation_id: 0,
                    control_id: 0,
                    actuator_id: 0,
                    sensor_id: 0,
                },
                new_model_id: 0,
                new_model: '',
                accessory_type_id: 2,
                revision: 'A'
            },
        }
    },
    methods: {
        save: function() {
            if (!this.errors()) {
                this.$emit('save', this.editCurb);
            }
        },
        cancel: function() {
            this.$emit('cancel');
        },
        errors: function() {
            let errors = [];
            if (!parseInt(this.editCurb.new_model_id) && !parseInt(this.editCurb.cdi_part_id_new)) {
                errors.push('Need to select New Model');
            }
            if (this.editCurb.conforming == 1 && !this.editCurb.conforming_type_id){
                errors.push('Conforming Stamp Location and Year is required.');
            }
            if (errors.length) {
                this.$appStore.errorBox(errors.join("\n"));

                return true;
            }

            return false;
        },
        load: function() {
            for (const field in this.editCurb) {
                this.editCurb[field] = this.curb[field];
            }
        },
    },
    created() {
        if (this.curb.tracker_invoice_id) {
            this.load();
        }
    }
}
</script>

<style scoped>

</style>