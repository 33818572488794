<template>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
        <label>Sensor Type</label>
        <select v-model="sensor_id" class="form-control" @change="$emit('update:val', sensor_id)">
            <option v-for="sensorOption in cache.curbTypeCache.ECONOMIZER_SENSOR_TYPE" :key="sensorOption[0]" :value="sensorOption[0]">{{ sensorOption[1] }}</option>
        </select>
    </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
export default {
    name: "Sensor",
    props: ['val'],
    data() {
        return {
            sensor_id: 0,
            cache: {
                curbTypeCache
            },
        }
    },
    computed: {},
    created() {
        this.sensor_id = this.val;
    },
}
</script>