<template>
    <div class="row">
        <div class="hero_video">
            <video autoplay muted loop class="video-background" playsinline>
                <source  :src="`https://media.cdicurbs.com/CDI_Banner_2024.mp4`" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
        <br/>

        <div class="row text-center home_slogan">
            <div class="col-sm-12">
                <div class="slogan">Custom Curb Adapters - New Construction Curbs - Isolation Curbs - Conforming/Calculated Curbs - Accessories - More</div>
            </div>
        </div>
        <div class="col-sm-12 text-center">
<!--            <img src="https://media.cdicurbs.com/christmas-2024.jpg" class="holiday_img" >-->
<!--            <img src="https://media.cdicurbs.com/hero-setting-curb.jpg" class="holiday_img" >-->
        </div>
        <div class="col-sm-10 col-sm-offset-1 home_link_boxes_container">
            <div class="col-lg-4 home_link_boxes">
                <div class="panel shadow">
                    <div class="panel-body">
                        <a class="home_circle_text" href="/my/register">
                            <div class="home_circle_text">Create Account</div>
                            <div class="circle-icon">
                                <span class="glyphicon glyphicon-user home_icon"></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 home_link_boxes">
                <div class="panel shadow">
                    <div class="panel-body">
                        <div class="home_circle_text">
                            <router-link :to="`/quote/new`">
                                Create a Quote</router-link>
                            <div class="circle-icon">
                            <span class="glyphicon glyphicon-edit home_icon"></span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 home_link_boxes">
                <div class="panel shadow">
                    <div class="panel-body">
                        <a class="home_circle_text" href="/employment">
                            <div class="home_circle_text">Join Our Team</div>
                            <div class="circle-icon">
                                <span class="glyphicon glyphicon-briefcase home_icon"></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {store} from "@/store/BusinessObject.store";
export default {
    name: 'home',
    components: {},
    data() {
        return {
            appState: this.$appStore.state,
            state: store.state,
            baseURL: process.env.VUE_APP_URL
        }
    },
}
</script>

<style scoped>

.home_slogan {
    background-color: #2f3033;
    color: white;
}

.holiday_img {
    width: 80%;
    margin-top: 10% !important;
}

@media screen and (max-width: 1220px) {
     .holiday_img {
         margin-top: 20% !important
     }
 }

@media screen and (max-width: 615px) {
    .holiday_img {
        margin-top: 25% !important
    }
}

@media screen and (max-width: 615px) {
    .holiday_img {
        margin-top: 25% !important
    }
}

@media screen and (max-width: 500px) {
    .holiday_img {
        margin-top: 40% !important;
        width: 100%;
    }
}

@media screen and (max-width: 460px) {
    .holiday_img {
        margin-top: 50% !important;
        width: 100%;
    }
}

@media screen and (max-width: 360px) {
    .holiday_img {
        margin-top: 60% !important;
        width: 100%;
    }
}







</style>
