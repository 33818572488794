<template>
    <div>
        <div class="spacing_btm">
            <ProtectedLink :queryString="queryString" :displayInline="true" :linkType="'button'" :disabled="!invoice.invoice_sent" :text="'PDF'" :classes="'btn btn-default'" :id="this.state.object.data.tracker_id" :type="'invoicePDF'" />
            <ProtectedLink :queryString="queryString" :displayInline="true" :linkType="'button'" :disabled="!invoice.invoice_sent" :text="'Email'" :classes="'btn btn-default'" :id="this.state.object.data.tracker_id" :type="'invoiceEmail'" />
            <input type='button' @click="toggleLocked" value="Toggle Locked Status" class="btn btn-default">
        </div>

        <div class="panel panel-default">
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTextarea
                        :field="{
                            name: 'invoice_notes',
                            qsField: '[invoice_notes]',
                            label: 'Invoice Notes'
                        }"
                        :val="invoice.invoice_notes"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect
                        :field ="billTo.field"
                        :val ="billTo.val"
                        @save ="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsStatic
                        :field = billToAddress.field
                        :val ="invoice.bill_to_address"
                        @save ="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect
                        :field="{
                            qsField: '[payment_term_id]',
                            name: 'payment_term_id',
                            label: 'Terms',
                            options: this.cache.paymentCache.TERMS,
                            excludeDefault: true,
                            disabled: state.object.data.locked,
                        }"
                        :val="invoice.payment_term_id"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <div class="object_detail">
                        <label class="object_detail">Payments</label>
                        <div class="form-control">
                            <span v-if="invoice.paid == 0 && invoice.paid_amount == 0">Unpaid</span>
                            <span v-for="check in invoice.checks" :key="check" style="padding-right: 5px;"><router-link :to="`/payment/${check}`">#{{ check }}</router-link></span>
                            <div v-if="!invoice.paid && invoice.past_due_sent">Past Due Notice Sent</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :field="{
                            name: 'invoice_sent',
                            qsField: '[invoice_sent]',
                            label: 'Invoice Sent',
                            disabled: state.object.data.locked,
                        }"
                        :val="invoice.invoice_sent"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :field="{
                            name: 'check_invoice',
                            qsField: '[check_invoice]',
                            label: 'Check Invoice',
                            disabled: state.object.data.locked,
                        }"
                        :val="invoice.check_invoice"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox
                        :field="{
                            val: '',
                            name: 'disputed',
                            qsField: '[disputed]',
                            label: 'Mark as Disputed'
                        }"
                        :val="invoice.disputed"
                        @save="save"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsStatic
                        :field="{
                                val: '',
                                name: 'tax_rate',
                                qsField: '[tax_rate]',
                                label: 'Tax Rate',
                                disabled: state.object.data.locked,
                                reset:true,
                        }"
                        :val="invoice.tax_rate"
                    />
                </div>
            </div>
        </div>
        <PricingUtilities
            v-if="!creditCardLock"
            @recalculateTotal="recalculateTotal"
            @zeroSurcharge="zeroSurcharge"
            @applySurcharge="applySurcharge"
        />
        <TotalDiscount
            v-if="!creditCardLock"
            @applyTotalDiscount="applyTotalDiscount"
            @saveTotalDiscount="(discount) => {
              saveTotalDiscount(discount)
            }"
            :discounts="this.invoice"/>

        <div v-if="!creditCardLock" class="panel panel-default spacing_top" v-show="surchargePermission">
            <div class="panel-heading"><strong>Surcharges</strong></div>
            <div class="row panel-body details_container">
                <div v-for="(surcharge, index) in invoice.surcharges"  v-bind:key="index" >
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <DetailsText
                            :field="{
                                label: `${surcharge.surcharge_label}`,
                               }"
                            :val="surcharge.surcharge_amount"
                            @save="updateSurcharge"
                        />
                    </div>
                </div>
            </div>
        </div>

        <InvoiceItems
            :creditCardLock = "creditCardLock"
            :invoice="this.invoice"
            @updateInvoice="updateInvoice"
        />

        <Modal @close='closeEmailModal' v-if='showConfirmEmailModal' :heading='`Order Email`'>
            <template v-slot:body >
                <div>
                    <p class='spacing_btm'>Email Customer a copy of their order?</p>
                    <label>Notes:</label>
                    <textarea rows='5' v-model='confirmBoxNotes' class='form-control' ></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <div>
                    <button class="btn btn-primary" @click="sendEmailConfirmation">Yes</button>
                    <button class="btn btn-default" @click="closeEmailModal">No</button>
                </div>
            </template>
        </Modal>

        <Toast @toastAction='openEmailModal'/>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store';
import DetailsStatic from '@/components/businessObjects/utils/DetailsStatic';
import DetailsTextarea from '@/components/businessObjects/utils/DetailsTextarea';
import DetailsSelect from '@/components/businessObjects/utils/DetailsSelect';
import DetailsCheckbox from '@/components/businessObjects/utils/DetailsCheckbox';
import paymentCache from '@/cache/payment.cache';
import InvoiceItems from './InvoiceItems';
import Toast from "@/components/utils/Toast";
import Modal from "@/components/utils/Modal";
import PricingUtilities from "@/components/businessObjects/tracker/PricingUtilities";
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";
import appStore from "@/store/App.store";
import {cdiVars} from "@/cdiVars";
import DetailsText from "@/components/businessObjects/utils/DetailsText.vue";
import TotalDiscount from "@/components/businessObjects/tracker/TotalDiscount";

export default {
    name: 'Invoice',
    components: {
        DetailsText,
        PricingUtilities,
        Toast,
        DetailsStatic,
        DetailsTextarea,
        DetailsSelect,
        DetailsCheckbox,
        InvoiceItems,
        Modal,
        ProtectedLink,
        TotalDiscount,
    },
    data() {
        return {
            state: store.state,
            invoice: {},
            cache: {
                paymentCache
            },
            billTo: {
                field: {
                    qsField: '[company_bill_to_id]',
                    name: 'company_bill_to_id',
                    label: 'Bill To',
                    display: '',
                    options: [[0, 'None']]
                },
                val: ''
            },
            billToAddress: {
                field: {
                    qsField: '[bill_to_address]',
                    name: 'bill_to_address',
                    label: 'Invoice Method:',
                }
            },
            showConfirmEmailModal: false,
            showFreightSurchargeModal: false,
            confirmBoxNotes: ''
        }
    },
    props: ['creditCardLock'],
    methods: {
        updateSurcharge: function(data){
            store.apiGeneric(`tracker/update_surcharge/${this.state.object.data.tracker_id}`, {
                'params[surcharge_amount]': data.val}).then(result => this.invoice = this.processApiResponse(result));
        },

        invoicePdf: function() {
            window.open(`${this.$cdiVars.API_URL}/tracker_pdf/invoice/${this.state.object.data.tracker_id}?params[seed]=${Math.random()}`, '_blank');
        },
        invoiceEmail: function() {
            let params = {'params[seed]': Math.random(), 'params[email]': 1};
            store.api('invoice', params, '', 'tracker_pdf');
        },

        save: function(data) {
            if(this.invoice.invoice_sent === 1 && ![37133, 2927].includes(this.$appStore.state.user.userID)){
            appStore.errorBox("This invoice has already been sent to the customer or the order is locked. Please contact Accounting to proceed with changes to this invoice.");
            }else{
                if (data.field === 'disputed') {
                    store.save(data.field, data.val);
                }else if (data.field === 'btax') {
                    let params = {[`params[${data.field}]`]: data.val};
                    store.api('add_sales_tax', params).then(result => this.invoice = this.processApiResponse(result));
                } else if (data.field == 'invoice_sent' || data.field == 'check_invoice' || data.val || data.val === 0) {
                    let params = {[`params[${data.field}]`]: data.val};
                    store.api('save_invoice_detail', params).then(result => this.invoice = this.processApiResponse(result));
                }
            }
        },

        loadInvoice: function() {
            store.api('invoice_to_json', {}).then(result => {
                this.invoice = this.processApiResponse(result);
            });
        },
        processApiResponse: function(result) {
            this.billTo.field.options = result.bill_to_li;
            this.billTo.val = result.company_bill_to_id
            this.billToAddress.field.label = `Invoice Method: ${result.bill_to_address_method || ''}`;

            if (result.toast_message) {
                this.confirmBoxNotes = result.toast_message;
                this.$appStore.toast(`Order updated!`,`<a class="tracker_toast_message alert-link">Email the customer an updated version of the order?</a>`);
            }
            return result;
        },
        updateInvoice: function(invoice) {
            this.invoice = this.processApiResponse(invoice);
        },
        openEmailModal: function() {
            this.showConfirmEmailModal = true;
        },
        closeEmailModal: function() {
            this.showConfirmEmailModal = false;
            this.confirmBoxNotes = '';
        },
        sendEmailConfirmation: function() {
            store.api('send_order_confirmation_email', {'params[confirm_box_notes]' : this.confirmBoxNotes});
            this.closeEmailModal();
        },
        toggleLocked: function() {
            store.api('toggle_locked_status').then((result) => {
                this.state.object.data.locked = result.locked;
                this.loadInvoice()
            });
        },
        recalculateTotal: function() {
            if (this.$appStore.state.user.userID == cdiVars.MANAGER_ACCOUNTING){
                store.api('recalculate_total').then(result => this.invoice = this.processApiResponse(result));
            } else {
                if (this.invoice.invoice_sent == 0 && this.state.object.data.locked == 0 && !this.creditCardLock) {
                    store.api('recalculate_total').then(result => this.invoice = this.processApiResponse(result));
                } else {
                    appStore.errorBox("This invoice has already been sent to the customer or the order is locked. Please uncheck the Invoice Sent checkbox/Unlock the order to proceed with changes to this invoice.");
                }
            }
        },
        zeroSurcharge: function() {
            if (this.$appStore.state.user.userID == cdiVars.MANAGER_ACCOUNTING){
                store.api('zero_surcharge').then(result => this.invoice = this.processApiResponse(result));
            } else {
                if (this.invoice.invoice_sent == 0 && this.state.object.data.locked == 0 && !this.creditCardLock) {
                    store.api('zero_surcharge').then(result => this.invoice = this.processApiResponse(result));
                } else {
                    appStore.errorBox("This invoice has already been sent to the customer or the order is locked. Please uncheck the Invoice Sent checkbox/Unlock the order to proceed with changes to this invoice.");
                }
            }
        },
        applySurcharge: function() {
            if (this.$appStore.state.user.userID == cdiVars.MANAGER_ACCOUNTING){
                store.api('calculate_surcharge').then(result => this.invoice = this.processApiResponse(result));
            } else {
                if (this.invoice.invoice_sent == 0 && this.state.object.data.locked == 0 && this.creditCardLock) {
                    store.api('calculate_surcharge').then(result => this.invoice = this.processApiResponse(result));
                } else {
                    appStore.errorBox("This invoice has already been sent to the customer or the order is locked. Please uncheck the Invoice Sent checkbox/Unlock the order to proceed with changes to this invoice.");
                }
            }
        },
      saveTotalDiscount: function(discount) {

          store.api('save_total_discount', {'params': discount}).then( result => this.invoice = this.processApiResponse(result));
      },
      applyTotalDiscount: function() {
        if (this.$appStore.state.user.userID == cdiVars.MANAGER_ACCOUNTING){
          store.api('apply_total_discount').then(result => this.invoice = this.processApiResponse(result));
        } else {
          if (this.invoice.invoice_sent == 0 && this.state.object.data.locked == 0) {
            store.api('apply_total_discount').then(result => this.invoice = this.processApiResponse(result));
          } else {
            appStore.errorBox("This invoice has already been sent to the customer or the order is locked. Please uncheck the Invoice Sent checkbox/Unlock the order to proceed with changes to this invoice.");
          }
        }
      },
    },
    computed: {
        // todo: remove this and permission comment after shipping PR is merged
        // invoiceSentActive: function() {
        //     return (this.store.object.data.actual_ship && this.invoice.company_bill_to_id);
        // }
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        },
        surchargePermission: function () {
            return this.$appStore.hasRole([this.$cdiVars.CDI_ROLE_SUPER]);
        }
    }
}
</script>