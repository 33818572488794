<template>
    <div>
        <div v-if="id">
            <h1>{{ state.object.data.company_name }}</h1>
            <ObjectState :object="state.object.data"/>
            <Dashboard :data="state.object.data" v-show="isCurrentView('dashboard')" />
            <DashboardBranches :data="branches" v-show="isCurrentView('dashboard')" @sort="getBranches" />
            <Details :data="state.object.data" v-show="isCurrentView('details')" />
            <ItemDiscount :data="state.object.data" v-show="isCurrentView('itemDiscount')" />
            <VolumeDiscount :data="state.object.data" v-show="isCurrentView('volDiscount')" />
            <NewBranch v-show="isCurrentView('newBranch')" />
            <BillTo :billTos="state.object.data.bill_tos" v-show="isCurrentView('billTo')" />
            <PricingGroups :pricingGroups="state.object.data.pricing_groups" v-show="isCurrentView('priceGroup')" />
            <Files :filesHTML="this.filesHTML" v-show="isCurrentView('files')" />
            <History v-show="isCurrentView('history')" />
        </div>

        <New v-else-if="isCurrentView('new')" />
        
        <Search v-else />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import { companyStore } from './Company.store.js';
import Search from '@/components/businessObjects/utils/Search.vue';
import Dashboard from './Dashboard';
import DashboardBranches from './DashboardBranches';
import ItemDiscount from './ItemDiscount';
import VolumeDiscount from './VolumeDiscount';
import NewBranch from './NewBranch';
import BillTo from "./BillTo";
import PricingGroups from "./PricingGroups";
import Files from "./Files";
import New from "./New";
import Details from '@/components/businessObjects/utils/Details';
import History from '@/components/businessObjects/utils/History';
import ObjectState from "@/components/businessObjects/utils/ObjectState.vue";

export default {
    name: "Container",
    data() {
        return {
            state: store.state,
            companyState: companyStore.state,
            branches: {},
            branchSort: '',
            branchSortDir: '',
            filesHTML: '',
        }
    },
    props: ['id'],
    components: {
      ObjectState,
        Search,
        Dashboard,
        DashboardBranches,
        ItemDiscount,
        VolumeDiscount,
        NewBranch,
        BillTo,
        PricingGroups,
        Details,
        History,
        Files,
        New,
    },
    created() {
        store.initialize(this.companyState);
        this.load();
    },
    methods: {
        load: function() {
            if (this.id){
                store.load(this.id, 'dashboard')
                    .then(() => {
                        this.$appStore.setTitle([this.state.object.data.company_name, this.state.titlePlural]);
                        this.state.breadcrumbs = [
                            {
                                label: this.state.object.data.company_name,
                                slug: '/company/' + this.state.object.data.company_id
                            }
                        ];
                    });
                this.getBranches();
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        getBranches: function(sort = '') {
            let options = {};
            if (sort){
                if (this.branchSortDir != '' || sort != this.branchSort){
                    this.branchSortDir = '';
                }else{
                    this.branchSortDir = 'DESC';
                }
                this.branchSort = sort;
                options = { 'params[orderby]': sort, 'params[direction]': this.branchSortDir };
            }
            store.api('company_branches', options)
                .then(result => this.branches = result);
        },
    },
    computed: {
        currentView: function() {
            return this.state.currentView;
        }
    },
    watch: {
        id: function (){
            this.load();
        },
        currentView: function() {
            if (this.state.currentView == 'history'){
                store.getHistory();
            }else if (this.state.currentView == 'files'){
                store.api('files_html', {})
                    .then(result => this.filesHTML = result);
            }else if (this.state.currentView == 'dashboard'){
                this.getBranches();
            }else if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}
</script>

<style scoped>

</style>