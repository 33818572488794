<template>
    <div>
        <h3>New Accessory</h3>
        <form @submit.prevent="save">
            <div class="row">
                <div class="form-group col-sm-6 col-xs-12">
                    <label>Accessory Type</label>
                    <select class="form-control" v-model="edit_accessory.curb_type_id">
                        <option v-for="[id, type] in cache.curbTypeCache.CURB_TYPE_ACCESSORIES" v-bind:key="id" :value="id">
                            {{ type }}
                        </option>
                    </select>
                </div>
                <div v-show="edit_accessory.curb_type_id === 25" class="form-group col-sm-6 col-xs-12">
                    <label>Accessory #</label>
                    <input type="text" v-model="edit_accessory.nomenclature" class="form-control">
                </div>
            </div>
            <div v-show="edit_accessory.curb_type_id === 26">
                <h3>Economizer Options</h3>
                <div class="row">
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Part #</label>
                        <Typeahead ref="miscPart" cdi_type="pm_model_conforming" v-bind:ID.sync="edit_accessory.cdi_part_id_new"/>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Product Type</label>
                        <select class="form-control" v-model="edit_accessory.product_type_id" >
                            <option v-for="[id, type] in cache.curbTypeCache.ECONOMIZER_PRODUCT_TYPE" v-bind:key="id" :value="id">
                                {{ type }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Orientation Type</label>
                        <select class="form-control" v-model="edit_accessory.orientation_id" >
                            <option v-for="[id, type] in cache.curbTypeCache.ECONOMIZER_ORIENTATION_TYPE" v-bind:key="id" :value="id">
                                {{ type }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Control Type</label>
                        <select class="form-control" v-model="edit_accessory.control_id" >
                            <option v-for="[id, type] in cache.curbTypeCache.ECONOMIZER_CONTROL_TYPE" v-bind:key="id" :value="id">
                                {{ type }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Actuator Type</label>
                        <select class="form-control" v-model="edit_accessory.actuator_id" >
                            <option v-for="[id, type] in cache.curbTypeCache.ECONOMIZER_ACTUATOR_TYPE" v-bind:key="id" :value="id">
                                {{ type }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Sensor Type</label>
                        <select class="form-control" v-model="edit_accessory.sensor_id" >
                            <option v-for="[id, type] in cache.curbTypeCache.ECONOMIZER_SENSOR_TYPE" v-bind:key="id" :value="id">
                                {{ type }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <button class="btn btn-default">Save Part</button>
        </form>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js'
import partTypeCache from '@/cache/partType.cache.js';
import curbTypeCache from "@/cache/curbType.cache";
import Typeahead from "@/components/utils/typeaheads/Typeahead.vue";
// import Typeahead from "@/components/utils/typeaheads/Typeahead.vue";
export default {
    name: "New",
    components: {Typeahead},
    data() {
        return {
            accessory: {
                cdi_part_id_new: 0,
                curb_type_id: 0,
                curb_type: 0,
                accessory_type_id: 0,
                product_type_id: 0,
                orientation_id: 0,
                control_id: 0,
                actuator_id: 0,
                sensor_id: 0,
                nomenclature: '',
                revision:'',
            },
            edit_accessory:{
                cdi_part_id_new: 0,
                curb_type_id: 0,
                curb_type: 0,
                accessory_type_id: 0,
                product_type_id: 0,
                orientation_id: 0,
                control_id: 0,
                actuator_id: 0,
                sensor_id: 0,
                nomenclature: '',
                revision: 'A'
            },
            state: store.state,
            cache: {
                partTypeCache,
                curbTypeCache
            },
            part: '',
            typeID: ''
        }
    },
    created() {
        store.clearObjectData();
    },
    methods: {
        save: function() {
            let params = {};
            this.edit_accessory['accessory_type_id'] = this.accessoryTypeIDSelect(this.edit_accessory.curb_type_id);
            for (const field in this.edit_accessory) {
                if (this.edit_accessory[field] !== this.accessory[field]) {
                    params[`params[${field}]`] = this.edit_accessory[field];
                }
            }
            store.api('new_accessory', params).then(result => {
                if (result.curb_id) {
                    this.$router.push({path: `/${ this.state.nav.base }/${ result.curb_id }`});
                }
            });
        },
        pmSelect: function(field, selected) {
            this.accessory[`new_model_id`] = selected.unit_model_id;
            this.accessory[`${field}_model`] = selected.label;
        },
        accessoryTypeIDSelect: function(curbTypeID){
            //Return to this and write a better function
            if(curbTypeID === 25) {
                return 1;
            } else if(curbTypeID === 26) {
                return 2;
            }
        },
    },
    computed: {
        modelID: function() {
            return this.accessory.new_model_id;
        },
        curbTypeID: function() {
            return this.accessory.curb_type_id
        }
    },
}
</script>

<style scoped>
</style>