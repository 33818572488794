<template>
    <div class="col-sm-12">
        <h3>Pricing USD <small>(Includes Freight and Total Number of Items)</small></h3>
        <div v-if="totalDiscount" class="alert alert-info right" role="alert">{{ totalDiscount }} total discount applied</div>
        <table class="table">
            <thead>
            <tr>
                <th class="first" >Item</th>
                <th></th>
<!--                <th class="number">Unit Price</th>-->
                <th class="number">Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(price, index) in products" v-bind:key="index">
                <td class="first" :class="{'text-danger': quoteCurbs[index].price_override > 0 && $appStore.isStaff()}">{{price.label}}</td>
                <td></td>
<!--                <td class="number" :class="{'text-danger': quoteCurbs[index].price_override > 0 && $appStore.isStaff()}"><span v-if="price.unit_price">${{(quoteCurbs[index].price_override > 0) ? Math.round(quoteCurbs[index].price_override) : price.unit_price}}</span> </td>-->
                <td class="number" :class="{'text-danger': quoteCurbs[index].price_override > 0 && $appStore.isStaff()}" :title="price.price_explain">
                    <small>{{ price.surcharge_desc }}</small>
                    <span v-if="price.non_discounted_prices" class="strikeout">{{ price.non_discounted_prices[0] }}</span>
                    {{priceDisplay(price.no_price, price.prices[0])}}
                </td>
            </tr>
            <tr v-if="surchargeTotal">
                <th>Surcharges</th>
                <th class="number"></th>
                <th class="number">Price</th>
            </tr>
            <tr v-for="(surcharge) in surcharges" v-bind:key="surcharge.surcharge_label">
                <td v-show="surchargeTotal" >{{ surcharge.surcharge_label }}</td>
                <td v-show="surchargeTotal" class="number"></td>
                <td v-show="surchargeTotal" class="number">${{ surcharge.surcharge_amount }}</td>
            </tr>
            </tbody>
            <thead>
            <tr>
                <th class="first">Ship Day</th>
                <th class="number"></th>
                <th class="number">Total</th>
            </tr>
            </thead>
            <tbody v-if="pricing && pricing.prompt_for_address">
                <tr>
                    <td class="first"><span @click="$refs.shipToState.open()" class="cdi_link">During this busy time, shipping state and zip code are required to determine ship date options.</span> <input @click="$refs.shipToState.open()" type="button" value="Enter Shipping Info" class="btn btn-default"></td>
                    <td class="number"> </td>
                    <td class="number">{{totalDisplay(leadTimes[0].total)}}</td>
                </tr>
            </tbody>

            <tbody v-else>
                <tr v-for="leadTime in leadTimes" v-bind:key="leadTime.build_time_id">
                    <td class="first">{{leadTime.ship_date}}</td>
                    <td class="number"> </td>
                    <td class="number">{{totalDisplay(leadTime.total)}}</td>
                </tr>
            </tbody>
        </table>

        <table class="table" v-if="freight.ltl || freight.truckload">
            <thead>
            <tr>
                <th>Freight</th>
                <th class="right">Estimated Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>LTL 18%</td>
                <td class="number">${{ freight.ltl.toLocaleString('en') }}</td>
            </tr>
            <tr>
                <td>Truckload 12.9%</td>
                <td class="number">${{ freight.truckload.toLocaleString('en') }}</td>
            </tr>
            </tbody>
        </table>

        <ShipToState ref="shipToState" />
    </div>
</template>

<script>
import ShipToState from "./ShipToState.vue";
export default {
    name: "Pricing",
    components: { ShipToState },
    props: ['pricing', 'quoteCurbs', 'freight', 'surcharges', 'surchargeTotal'],
    methods: {
        priceDisplay: function(noPrice, price) {
            return (noPrice ? noPrice : price);
        },
        totalDisplay: function(total) {
            total = parseInt(total.replace(/\D/g,''));
            if (this.surchargeTotal) {
                total += parseInt(this.surchargeTotal);
            }
            return (this.pricing.valid_total ? '$' + total.toLocaleString('en') : '1 or more items do not have a price.');
        },
    },
    computed: {
        products: function() {
            return (this.pricing ? this.pricing.products : {});
        },
        leadTimes: function() {
            return (this.pricing ? this.pricing.lead_time : {});
        },
        totalDiscount: function() {
            return (this.pricing ? this.pricing.total_discount: 0);
        }
    },
}
</script>

<style scoped>

</style>