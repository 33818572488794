<template>
    <div>
        <div class="row">
            <h1>Purchased Items</h1>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>New Material</label><br>
                <input type="text" v-model="rawMaterial" class="form-control">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Category</label><br>
                <select v-model="category" class="form-control">
                    <option v-for="[index, option] in this.cache.materialCache.MATERIAL_CATEGORIES" v-bind:key="index" :value="index">{{ option }}</option>
                </select>
            </div>
            <div class="col-sm-12 pl-0">
                <div class="col-sm-12 col-md-9">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th colspan="3">Inventory Controls</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Track Inventory</td>
                            <td>Enables inventory controls for manual inventory tracking.</td>
                            <td class="number"><input type="checkbox" :true-value="1" :false-value="0" v-model="track_inventory" class="" /></td>
                        </tr>
                        <tr>
                            <td>Inventory Manager</td>
                            <td>Adds this item to the inventory manager tool.</td>
                            <td class="number"><input type="checkbox" :true-value="1" :false-value="0" v-model="manage_inventory" class="" /></td>
                        </tr>
                        <tr>
                            <td>Track FIFO</td>
                            <td>Enables FIFO tracking from Corpay receipts.</td>
                            <td class="number"><input type="checkbox" :true-value="1" :false-value="0" v-model="track_fifo" class="" /></td>
                        </tr>
                        <tr>
                            <td>Corpay ID</td>
                            <td>
                                Automated inventory tracking requires a Corpay ID to synchronize POs.
                                Find the corresponding item in the Corpay catalog, open the item details, and copy the ID from the URL.
                                FIFO inventory will not work until this ID is populated.
                                <br /><br/>
                                <button class="btn btn-default" onclick="window.open('https://app.accrualify.com/catalogue_items', '_blank')">Corpay Catalog</button>
                            </td>
                            <td><input type="text" v-model="corpay_id"></td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
        <input type="button" class="cdi_button btn btn-default" @click="saveNewMaterial" value="Add Material">
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import materialCache from '@/cache/material.cache';

export default {
    name: 'New',
    data() {
        return {
            state: store.state,
            rawMaterial: '',
            category: 4,
            track_inventory: 0,
            manage_inventory: 0,
            track_fifo: 0,
            corpay_id: '',
            cache: {
                materialCache
            }
        }
    },
    methods: {
        saveNewMaterial: function() {
            this.state.object.id = 0;
            let params = {
                'params[raw_material]' : this.rawMaterial,
                'params[inventory_category_id]' : this.category,
                'params[track_inventory]' : this.track_inventory,
                'params[manage_inventory]' : this.manage_inventory,
                'params[track_fifo]' : this.track_fifo,
                'params[corpay_id]' : this.corpay_id,
            }
            store.api('new_raw_material', params).then(result =>
                this.$router.push(`/material/${result.raw_material_id}`)
            );
        }
    }
}

</script>