<template>
    <div>
        <h3>Other Settings</h3>

        <div class="panel panel-default">
            <div class="row panel-body details_container">
                <div class="col-lg-4 col-sm-6 col-xs-12" v-show="!isEmployee">
                    <DetailsSelect :field="{name: 'brand_id', label: 'Brand', options: cache.brandCache.BRANDS}"
                                   :val="data.brand_id"
                                   @save="sales"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'branch_id', label: 'Special Tag', options: cache.userCache.USER_SPECIAL_TAGS}"
                                   :val="data.branch_id"
                                   @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead :field="{
                                            name: 'sales_user_id',
                                            label: 'Regional Sales Rep',
                                            display: this.data['sales_user'],
                                            cdiType: 'employee',
                                            link: 'user'
                                          }"
                                      :val="data.sales_user_id"
                                      @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead :field="{
                                            name: 'inside_sales_user_id',
                                            label: 'Sales Support Rep',
                                            display: this.data['inside_sales_user'],
                                            cdiType: 'employee',
                                            link: 'user'
                                          }"
                                      :val="data.inside_sales_user_id"
                                      @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                  <DetailsSelect :field="{name: 'national_sales_team_id', label: 'National Sales Team', options: nationalSalesTeams}"
                                 :val="data.national_sales_team_id"
                                 @save="standard"
                  />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsCheckbox :field="{name: 'lock_sales', label: 'Lock Sales Team', display: 'Prevent the sales team from changing when mass territory change jobs are executed'}"
                                     :val="data.lock_sales"
                                     @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsTypeahead :field="{
                                            name: 'user_shipping_co_id',
                                            label: 'Default Shipper',
                                            display: this.data['user_shipping_co'],
                                            cdiType: 'shipping_co',
                                            link: 'shipper'
                                          }"
                                      :val="data.user_shipping_co_id"
                                      @save="website"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsText :field="{name: 'user_notes', label: 'Customer Notes'}"
                                 :val="data.user_notes"
                                 @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsStatic :field="{name: 'gapp_contact_id', label: 'GApp Contact'}"
                                   :val="data.gapp_contact_id"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12" v-show="!isEmployee">
                    <DetailsCheckbox :field="{name: 'targeted', label: 'Targeted Customer'}"
                                     :val="data.targeted"
                                     @save="sales"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'company_pricing_group_id', label: 'Pricing Group', options: companyPricingGroups}"
                                   :val="data.company_pricing_group_id"
                                   @save="website"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <DetailsSelect :field="{name: 'primary_branch_id', label: 'Branches', options: companyBranches}"
                                   :val="data.primary_branch_id"
                                   @save="standard"
                    />
                </div>
                <div class="col-lg-4 col-sm-6 col-xs-12">
                    <UserCCs />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/BusinessObject.store";
    import brandCache from "@/cache/brand.cache";
    import userCache from "@/cache/user.cache";
    import DetailsText from "@/components/businessObjects/utils/DetailsText";
    import DetailsSelect from "@/components/businessObjects/utils/DetailsSelect";
    import DetailsTypeahead from "@/components/businessObjects/utils/DetailsTypeahead";
    import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
    import DetailsCheckbox from "@/components/businessObjects/utils/DetailsCheckbox";
    import UserCCs from "@/components/businessObjects/utils/UserCcs.vue";
    import { cdiVars } from "@/cdiVars";

    export default {
        name: "OtherDetails",
        components: {
            UserCCs,
            DetailsCheckbox,
            DetailsStatic,
            DetailsTypeahead,
            DetailsSelect,
            DetailsText
        },
        props: ["data"],
        data() {
            return {
                cache: {
                    brandCache,
                    userCache
                },
              nationalSalesTeams: [
                [0,'Options Not Available']],
            }
        },
      created() {
        this.get_national_sales_teams();
      },
        methods: {
            standard: function (data) {
                store.save(data.field, data.val);
            },
            sales: function (data) {
                let field = `params[${data.field}]`;
                let options = {[field]: data.val};
                store.apiWithObjectLoad('save_user_sales', options);
            },
            website: function (data) {
                let field = `params[${data.field}]`;
                let options = {[field]: data.val};
                store.apiWithObjectLoad('save_user_website', options);
            },
            get_national_sales_teams: function() {
              store.customApi('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
            }
        },
        computed: {
            companyPricingGroups: function () {
                if (!this.data.company_pricing_groups || this.data.company_pricing_groups.length === 0) {
                    return [[0, 'Not Set']];
                }

                return Object.entries(this.data.company_pricing_groups);
            },
            companyBranches: function () {
                if (!this.data.company_branches || this.data.company_branches.length === 0) {
                    return [[0, 'Not Set']];
                }

                let options = [];
                Object.values(this.data.company_branches).map(row => options.push([row['branch_id'], row['branch_name']]));

                return options;
            },
            isEmployee: function () {
                return this.data.user_type_id === cdiVars.USER_TYPE_EMPLOYEE;
            }
        }
    }
</script>

<style scoped>

</style>